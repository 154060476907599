export default function Error() {
    return (
        <div className='currpage__row'>

            <div className='currpage__head'>
                <h1>PAGE NOT FOUND (404)</h1>
            </div>

            <div className='currpage__body'>
                <p>Sorry, try to use links above...</p>
            </div>
        
        </div>
    )
}
